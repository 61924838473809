import * as React from 'react';
import { Layout } from '../components/Layout';
import { Title } from '../components/Title';
import { Form } from '../components/Form';
import { Seo } from '../components/Seo';
import { Breadcrumbs } from '../components/Breadcrumbs';

function IndexSite({ location }) {
    return (
        <Layout location={location}>
            <Breadcrumbs
                crumbs={[
                    ['/', 'Главная'],
                    [null, '404'],
                ]}
            />
            <div className="container section-margin">
                <Title className="h1" level="h2">
                    Такой страницы не существует
                </Title>
            </div>
            <Form />
        </Layout>
    );
}

export default IndexSite;

export function Head() {
    return <Seo title="404" description="404 страница" />;
}
